.LoaderContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.LoaderContainer > img {
  width: 25px;
  height: 25px;
}
