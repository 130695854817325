.image-preview {
  display: flex;
  justify-content: center;
  padding: 20px 10px 10px;
  img {
    width: 50px;
    height: 50px; } }

.container-input {
  margin: 20px;
  .input-label {
    display: inline-block;
    min-width: 100px; }
  .input-error {
    color: red;
    padding: 10px;
    font-weight: bold;
    font-size: 11px; }
  .input {
    width: 100%;
    max-width: 300px;
    height: 40px;
    border-radius: 2px;
    box-shadow: none; }

  .input-textarea {
    width: 100%;
    max-width: 600px;
    height: 100px; } }

.rate-title {
  margin: 10px 0;
  font-weight: bold;
  font-size: 14px; }

.rate-option {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  .rate-available {
    display: flex;
    align-items: center;
    justify-content: space-between; }

  .rate-name {
    font-size: 12px;
    min-width: 100px;
    font-weight: 500; }

  input[type='checkbox'] {
    margin-left: 5px; }

  input[type='text'] {
    border: 1px solid #000;
    border-radius: 4px;
    box-shadow: px 2px 12px black;
    margin-left: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px; } }
