.container_item {
  padding: 20px;
  justify-content: space-between;
  align-items: center; }

.icons {
  a {
    color: gray; } }

.inactive {
  opacity: 0.5; }
